<template>
  <modal
    :width="1200"
    :adaptive="true"
    class="newPermissionModal CEModal modal-scroll-bar"
    name="NewPermissionModal"
  >
    <h4 class="weight-700 text-center m-b-30" v-if="!isEdit">
      {{ $t('MANAGEMENT.NEW_PROFILE') }}
    </h4>
    <h4 class="weight-700 text-center m-b-30" v-else>
      {{ $t('MANAGEMENT.EDIT_PROFILE') }}
    </h4>

    <div class="d-flex mb-5">
      <div class="d-flex align-items-center profile-name">
        <label>{{ $t('MANAGEMENT.PROFILE_NAME') }}</label>
        <input type="text" class="input profile-input mx-3" v-model="profileName">
      </div>
      <div class="d-flex align-items-center">
        <label>{{ $t('MANAGEMENT.PROFILE_NAME') }}</label>
        <vue-select
          :options="profile.options"
          class="input cus-select profile-dropdown mx-3"
          v-model="profile.selected"
        ></vue-select>
        <button class="primary-btn px-2" @click="importPermission">
          {{ $t('MANAGEMENT.IMPORT_PERMISSIONS') }}
        </button>
      </div>
    </div>
    <div class="m-b-40">
      <label>{{ $t('MANAGEMENT.PERMITTED_TO') }}</label>
      <vue-select
        :options="roles.options"
        class="input cus-select cus-multi-dropdown"
        v-model="roles.selected"
        multiple
      ></vue-select>
    </div>
    <div class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('COMMON.SEARCH') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="permissionTableData"
        class="cus-table editable-table main-table"
        item-key="id"
        :expanded.sync="expanded"
      >
        <template v-slot:[`item.show`]="{ item }">
          <div class="d-flex justify-content-center">
            <v-checkbox 
              color="info" 
              class="cus-checkbox" 
              v-model="item.show" 
              @change="changeAllState(item, 'show')" 
              v-if="item.show == 'mid'"
            ></v-checkbox>
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.show" @change="changeAllState(item, 'show')" v-else></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <div class="d-flex justify-content-center">
            <v-checkbox color="info" class="cus-checkbox" v-model="item.edit" @change="changeAllState(item, 'edit')" v-if="item.edit == 'mid'"></v-checkbox>
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.edit" @change="changeAllState(item, 'edit')" v-else></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.details`]="{ item }">
          <div class="d-flex justify-content-center align-items-center" >
            <div class="expand-btn plus pointer" @click="expanded = [item]" v-if="!expanded.includes(item)">
              <img src="/assets/img/icons/blue_arrow.svg" alt="">
            </div>
            <div class="expand-btn minus pointer" @click="expanded = []" v-if="expanded.includes(item)">
              <img src="/assets/img/icons/green_arrow.svg" alt="">
            </div>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="manual-card-edit">
             <v-data-table
              :headers="expandHeader"
              :items="item.permissions"
              class="cus-table editable-table"
              item-key="id"
              hide-default-header
              hide-default-footer
              disable-pagination
            >
              <template v-slot:body="props">
                <tr class="row_contract" v-for="item in props.items" :key="item.id">
                  <td style="width: 11%">

                  </td>
                  <td style="width: 34.4%">
                    <div class="d-flex justify-content-center">
                      {{ item.name }}
                    </div>
                  </td>
                  <td style="width: 22%">
                    <div class="d-flex justify-content-center">
                      <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.show" @change="changePermissionState(item, 'show')"></v-checkbox>
                    </div>
                  </td>
                  <td style="width: 26%">
                    <div class="d-flex justify-content-center">
                      <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.edit" @change="changePermissionState(item, 'edit')"></v-checkbox>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="d-flex align-items-center bottom-action-block">
      <button class="primary-btn primary-btn_sm" @click="save()">
        {{ $t('COMMON.SAVE') }}
      </button>
      <button class="primary-btn primary-btn_sm bg-red" @click="closeModal()">
        {{ $t('SUPPLIER_PAYMENT.CANCELATION') }}
      </button>
    </div>

  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "NewPermissionModal",
  props: {
    editId: {},
    isEdit: {
      type: Boolean
    }
  },
  data() {
    return {
      expanded: [],
      profileName: "",
      profile: {
        options: [],
        selected: ""
      },
      roles: {
        options: [],
        selected: []
      },
      permissionTableData: [],
      search: '',
      headers: [
        {
          text: "",
          align: 'center',
          sortable: false,
          value: 'details',
        },
        {
          text: this.$t('COMMON.NAME'),
          align: 'center',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('ADS_MANAGEMENT.SHOW'),
          align: 'center',
          sortable: false,
          value: 'show',
        },
        {
          text: this.$t('COMMON.EDIT'),
          align: 'center',
          sortable: false,
          value: 'edit',
        }
      ],
      expandHeader: [
        {
          text: "",
          align: 'center',
          sortable: false,
          value: 'blank',
        },
        {
          text: this.$t('COMMON.NAME'),
          align: 'center',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('ADS_MANAGEMENT.SHOW'),
          align: 'center',
          sortable: false,
          value: 'show',
        },
        {
          text: this.$t('COMMON.EDIT'),
          align: 'center',
          sortable: false,
          value: 'edit',
        }
      ],
      permissionRolesForm: {},
      activePermissions: []
    }
  },
  computed: {
    ...mapState({
      rolesList: (state) => state.system_settings.rolesList,
      permissions: (state) => state.system_settings.permissions,
      permissionRoles: (state) => state.system_settings.permissionRoles,
    }),
    basicTableData() {
      let newTableData = [];
      if (Object.keys(this.permissions).length != 0) {
        this.permissions.permissionGroups.forEach(el => {
          let permissionArry = this.permissions.permissions.filter(ele => ele.permissionGroupId == el.id);
          let updatedPermissionArry = [];
          if (permissionArry.length) {
            permissionArry.forEach(pArry => {
              updatedPermissionArry.push({
                id: pArry.id,
                name: pArry.name,
                show: false,
                edit: false,
                permissionGroupId: pArry.permissionGroupId
              })
            });
            newTableData.push({
              id: el.id,
              name: el.name,
              show: false,
              edit: false,
              permissions: updatedPermissionArry
            })
          }
        });
      }
      this.permissionTableData = JSON.parse(JSON.stringify(newTableData));
      return newTableData;
    }
  },
  async created() {
    if (Object.keys(this.permissions).length == 0) {
      await this.getPermissions();
    }
    if (Object.keys(this.permissions).length != 0) {
      this.permissions.roles.forEach(el => {
        this.profile.options.push({
          label: el.name,
          code: el.id
        })
      });;
    }
    if (this.rolesList.length == 0) {
      await this.getRoles();
    }
    if (this.rolesList.length != 0) {
      this.roles = {
        options: [],
        selected: ""
      };
      this.rolesList.forEach(el => {
        this.roles.options.push({
          label: el.name,
          code: el.id,
        });
      });

      this.roles.selected = [];
    }
    if (this.permissionRoles.length == 0) {
      await this.getPermissionRoles();
    }
  },
  methods: {
    ...mapActions("system_settings", {
      getPermissions: "getPermissions",
      getRoles: "getRoles",
      getPermissionRoles: "getPermissionRoles",
      updatePermissionRoles: "updatePermissionRoles",
    }), 
    closeModal() {
      this.$modal.hide("NewPermissionModal");
    },
    async save() {
      if (this.isEdit) {
        const role_id = this.editId;
        let roles = [];
        this.roles.selected.forEach(el => {
          roles.push(el.label);
        });
        await this.updatePermissionRoles({
          permissionRolesForm: this.permissionRolesForm,
          roleId: role_id,
          roles: roles
        });
        await this.getPermissions();
        await this.getPermissionRoles();
        this.setTableData(this.editId);
        this.closeModal();
      }
    },
    switchPermissionState(item, type) {
      if (type == 'show' && item.show) {
        item.edit = false;
      } else if (type == 'edit' && item.edit) {
        item.show = false;
      }
    },
    changePermissionState(item, type) {
      this.switchPermissionState(item, type);
      this.checkChildPermissions(item, type);
      this.createRolesForm(item);

    },
    changeAllState(item, type) {
      this.switchPermissionState(item, type);
      this.setAllCheckboxes(item, type);
      item.permissions.forEach(el => {
        this.createRolesForm(el);
      });
    },
    setAllCheckboxes(item, type) {
      this.permissionTableData.forEach(el => {
        if (item.id == el.id) {
          el.permissions.forEach(ele => {
            ele[type] = item[type];
            if (item[type]) {
              if (type == 'show') {
                ele.edit = false;
              } else {
                ele.show = false;
              }
            }
              
          });
        }
      })
    },
    getUnType(type) {
      if (type == 'show') return 'edit';
      else return 'show';
    },
    checkChildPermissions(item, type) {
      let currentGroup = this.permissionTableData.find(el => el.id == item.permissionGroupId);
      this.returnGroupPermissionFromChildren(currentGroup, type);
      this.returnGroupPermissionFromChildren(currentGroup, this.getUnType(type));
    },
    returnGroupPermissionFromChildren(item, type) {
      let checkedItem = 0;
      item.permissions.forEach(ele => {
        if (ele[type]) checkedItem = checkedItem + 1;
      });
      if (checkedItem == item.permissions.length) {
        item[type] = true;
      } else if (checkedItem == 0) {
        item[type] = false;
      } else {
        item[type] = "mid";
      }
    },
    fetchPermissionTable() {
      this.permissionTableData.forEach(el => {
        this.returnGroupPermissionFromChildren(el, "edit");
        this.returnGroupPermissionFromChildren(el, "show");
      })
    },
    createRolesForm(item) {
      this.permissionRolesForm[item.id] = [];
      if (item.show || item.edit) {
        let action = 1;
        if (item.edit) action = 2;  
        this.permissionRolesForm[item.id].push({
          action: action,
          permissionId: item.id,
          roleId: this.editId,
          date: null
        });
      } 
    },
    setTableData(id, isImport=false) {
      if (Object.keys(this.permissions).length != 0) {
        const selectedPermission = this.permissions.roles.find(el => el.id == id);
        if (!isImport) {
          this.profileName = selectedPermission.name;
        }
        this.roles.selected = [];
        selectedPermission.AllowedRoles.forEach(ele => {
          this.roles.selected.push({
            label: ele.name,
            code: ele.RoleAllowedRole.role_id
          })
        });
        this.permissionTableData = JSON.parse(JSON.stringify(this.basicTableData));
        this.permissionRolesForm = {};
        this.permissionRoles.forEach(el => {
          if (el.roleId == id) {
            const groupId = el.Permission.permission_group_id;
            this.permissionTableData.forEach(tpermission => {
              if (tpermission.id == groupId) {
                 let permissionTr = tpermission.permissions.find(p => {
                  return p.id == el.permissionId;
                });
                permissionTr[el.action] = true;
              }
            });
            this.permissionRolesForm[el.permissionId] = [];
            let tempEl = JSON.parse(JSON.stringify(el));
            if (tempEl.action == 'show') {
              tempEl.action = 1;
            } else {
              tempEl.action = 2;
            }
            tempEl.roleId = this.editId;
            this.permissionRolesForm[tempEl.permissionId].push(tempEl);
          }
        });
        this.fetchPermissionTable();
      }
    },
    importPermission() {
      const id = this.profile.selected.code;
      this.setTableData(id, true);
    }
  },
  watch: {
    editId: function(val) {
      if (this.isEdit) {
        this.setTableData(val);
      } else {
        this.permissionTableData = JSON.parse(JSON.stringify(this.basicTableData));
      }
    }
  }
}
</script>

<style lang="scss">
.newPermissionModal .vm--modal {
  background-color: #f3f3f3;
  padding: 20px 40px;
  max-height: 90% !important;
}
</style>

<style lang="scss" scoped>
.newPermissionModal {
  label {
    overflow: initial;
  }
  .profile-name {
    margin-right: 30px;
    .profile-input {
      width: 200px;
    }
  }
  .profile-dropdown {
    min-width: 200px;
  }
  .table-content-part {
    margin: 20px;
  }
}
</style>