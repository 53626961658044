<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('REGISTER_RESELLER.PROFILE_PERCENTAGE_PROFILE') }}
    </p>
    <section class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('COMMON.SEARCH') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="permissions.roles"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
        v-if="permissions"
      >
        <template v-slot:[`item.edit`]="{ item }">
          <v-icon medium color="dark" @click="openEditProfileModal(item.id)">edit</v-icon>
        </template>
        <template v-slot:[`item.can_assigned`]="{ item }">
          <div v-if="item.AllowedRoles.length" class="d-flex flex-wrap justify-content-center">
            <div class="grey-ticket" v-for="(aRole, index) in item.AllowedRoles" :key="index">
              {{ aRole.name }}
            </div>
          </div>
          <div v-else>
            N/A
          </div>
        </template>
      </v-data-table>
    </section>
    <div class="content-margin m-b-40 d-flex m-t-20">
      <button class="primary-btn btn-limit-width bg-yellow" @click="openNewProfileModal">
        {{ $t('MANAGEMENT.NEW_PROFILE') }}
      </button>
    </div>
    <NewPermissionModal :editId="editId" :isEdit="isEdit"></NewPermissionModal>
    <modal
      :width="550"
      :adaptive="true"
      class="newProfileModal CEModal modal-scroll-bar"
      name="newProfileModal"
    >
      <div class="modal-content">
        <div class="validation-form-group"
          :class="{ 'form-group--error': $v.newProfile.$error }">
          <label>{{ $t('MANAGEMENT.PROFILE_NAME') }} *</label>
          <input
            type="text" 
            class="input"
            v-model="newProfile"
            @input="$v.newProfile.$touch"
          />
          <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newProfile.required">
            {{ $t('VALIDATION.REQUIRED_FIELD') }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="primary-btn primary-btn_sm mx10" @click="saveNewProfile">
          {{ $t('DESTINATION.CONFIRM') }}
        </button>
        <button class="primary-btn primary-btn_sm mx10 bg-2" @click="closeModal">
          {{ $t('COMMON.CANCEL') }}
        </button>
      </div>
    </modal>
  </main>   
</template> 

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import NewPermissionModal from "../../../components/management/NewPermissionModal.vue"

export default {
  name: 'SCPermissionsProfiles',
  mixins: [validationMixin],
  components: {
    NewPermissionModal
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: this.$t('MANAGEMENT.PROFILE_NAME'),
          align: 'center',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('MANAGEMENT.CAN_ASSIGNED'),
          align: 'center',
          sortable: false,
          value: 'can_assigned',
        },
        {
          text: this.$t('COMMON.EDIT'),
          align: 'center',
          sortable: false,
          value: 'edit',
        }
      ],
      editId: '',
      isEdit: true,
      newProfile: ""
    }
  },
  validations: {
    newProfile: {
      required
    }
  },
  computed: {
    ...mapState({
      permissions: (state) => state.system_settings.permissions,
    }),
  },
  async created() {
    if (Object.keys(this.permissions).length == 0) {
      await this.getPermissions();
    }
  },
  methods: {
    ...mapActions("system_settings", {
      getPermissions: "getPermissions",
      getRoles: "getRoles",
      getPermissionRoles: "getPermissionRoles",
      createRole: "createRole"
    }), 
    openNewProfileModal() {
      this.$modal.show('newProfileModal');
    },
    closeModal() {
      this.$modal.hide('newProfileModal');
      this.newProfile = "";
    },
    openEditProfileModal(id) {
      this.editId = id;
      this.isEdit = true;
      this.$modal.show("NewPermissionModal");
    },
    saveNewProfile() {
      this.$v.$touch();
      if (
        this.$v.$anyError
      ) {
        return;
      }
      this.createRole({
        name: this.newProfile,
      }).then(async res => {
        this.closeModal();
        if (res.msg == "success") {
          await this.getPermissions();
          this.getRoles();
          this.getPermissionRoles();
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.newProfileModal {
  .modal-footer {
    padding: 0 40px 30px;
  }
}
</style>